
import React, { useContext, useState } from 'react'
import { ApiContext } from '../../Context/ApiContext'


const AddTopLinks = () => {
    const {API} = useContext(ApiContext)
    const [inputs, setInputs] = useState({})

    async function FormSubmit(event) {

        event.preventDefault();
        console.log(inputs);

        try {
            const response = await fetch(`${API}/api/admin/toplinks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputs)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Data sent successfully:', responseData);
            // navigate('/top-links')
            alert('sucess')
        } catch (error) {
            console.error('Error sending data:', error);
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs({ ...inputs, [name]: value })
    }

    

    return (
       
                <div className="content-wrapper">

                    <section className="content mt-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Top Khabare</h3>
                                        </div>
                                        <form onSubmit={() => FormSubmit} action="/post" method="POST" encType="multipart/form-data">
                                            <div className="card-body">

                                                
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Links's Name</label>
                                                    <input
                                                        onChange={handleChange}
                                                        name="name"
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Enter Your Name"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Link</label>
                                                    <input
                                                        onChange={handleChange}
                                                        name="link"
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Enter Your Designation"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Links's Postion</label>
                                                    <input
                                                        onChange={handleChange}
                                                        name="Position"
                                                        type="text"
                                                        value={inputs.Position}
                                                        class="form-control"
                                                        placeholder="Enter Your Postion"
                                                    />
                                                </div>

                                            </div>

                                            <div className="card-footer">
                                                <button type="submit" onClick={FormSubmit} className="btn btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            
    )
}

export default AddTopLinks