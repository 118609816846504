import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { ApiContext } from "../../Context/ApiContext";
import {
  Editblogs,
  getallcategories,
  getblogbyid,
} from "../../_service_api/ServiceApi";
import { CKEditor } from "@ckeditor/ckeditor5-react"; // Corrected import for CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; // CKEditor Classic Build

const EditBlogs = () => {
  // const params = useParams()
  const params = useParams();
  const { categorie, id } = params;

  const { API } = useContext(ApiContext);
  const navigate = useNavigate();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [categori, setcategories] = useState("");

  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [inputs, setInputs] = useState({
    ReporterName: "",
  });
  const [profileimage, setprofileimage] = useState({});
  const [image, setimage] = useState({});

  const getdata = async () => {
    const getblogdata = await getblogbyid(id);
    setInputs(getblogdata.data[0]);
    setContent(getblogdata.data[0].Matter);
    setcategories(getblogdata.data[0].Category_id.category);

    const categoriedata = await getallcategories();

    setSelectedValue((prevSelectedValue) => [
      ...prevSelectedValue, // Previous state values
      ...categoriedata.map((item) => ({
        value: item._id,
        label: item.category,
      })), // New state values
    ]);
  };

  useEffect(() => {
    getdata();
  }, []);

  async function FormSubmit(event) {
    event.preventDefault();
    const formData = await new FormData();

    // Display a confirmation alert before submitting the form
    const isConfirmed = window.confirm(
      "Are you sure you want to submit this form?"
    );

    if (!isConfirmed) {
      // If the user clicks "Cancel", stop the form submission
      return;
    }

    if (inputs.ReporterName && inputs.ReporterName.length > 0) {
      formData.append("ReporterName", inputs.ReporterName);
    }

    if (inputs.Designation && inputs.Designation.length > 0) {
      formData.append("Designation", inputs.Designation);
    }

    if (inputs.DatePlace && inputs.DatePlace.length > 0) {
      formData.append("DatePlace", inputs.DatePlace);
    }

    if (inputs.Heading && inputs.Heading.length > 0) {
      formData.append("Heading", inputs.Heading);
    }

    if (inputs.Capton && inputs.Capton.length > 0) {
      formData.append("Capton", inputs.Capton);
    }

    if (inputs.Headline !== undefined) {
      formData.append("Headline", inputs.Headline);
    }

    if (inputs.Subheading && inputs.Subheading.length > 0) {
      formData.append("Subheading", inputs.Subheading);
    }

    const currentDate = new Date().toISOString();
    formData.append("CreationDate", currentDate);

    // if (Array.isArray(selectedOption)) {
    // const positionValues = selectedOption.map((option) => option.value);
    // console.warn("selectedOption",selectedOption);
    // formData.append("Category", JSON.stringify(positionValues))
    if (selectedOption?.value) {
      formData.append("Category_id", selectedOption?.value);
    }
    // }

    if (profileimage instanceof File) {
      formData.append("profile", profileimage);
    }

    if (image instanceof File) {
      formData.append("image", image);
    }

    if (video.length > 0) {
      formData.append("Video", video[0]);
    }
    if (audio.length > 0) {
      formData.append("Audio", audio[0]);
    }
    formData.append("Matter", content);

    const result = await Editblogs(id, formData);
    const encodedCategory = encodeURIComponent(inputs.Category);
    result
      ? navigate(`/blogs/${categorie}?value=${encodedCategory}`)
      : navigate(`/blogs/${categorie}`);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    setInputs({ ...inputs, [name]: value });

    if (name === "Headline") {
      setInputs({ ...inputs, [name]: checked }); // Update the checkbox state only if its name is "isHeader"
    }
    console.warn(inputs);
  };

  const nhandleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.warn(selectedOption);
  };

  return (
    <div className="content-wrapper">
      <section className="content mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Edit News </h3>
                  <h4 className="card-title">{categori}</h4>
                </div>
                <form
                  onSubmit={() => FormSubmit}
                  action="/post"
                  method="POST"
                  encType="multipart/form-data"
                >
                  <div className="card-body">
                    {/* <div className="row">
                      <div className="col-md-12">
                        <div class="form-group">
                          <label for="exampleSelectRounded0">
                            Select Location for Display Khabar
                          </label>
                          <Select
                            id="selectOption"
                            name="Position"
                            // value={selectedcategories}
                            onChange={nhandleChange}
                            options={selectedValue}
                            // isMulti
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        {profileimage && profileimage instanceof Blob ? (
                          <img
                            className="mt-2"
                            style={{ width: "100px", height: "80px" }}
                            src={URL.createObjectURL(profileimage)}
                            alt=""
                          />
                        ) : (
                          <img
                            className="mt-2"
                            style={{ width: "100px", height: "80px" }}
                            src={
                              inputs.ReporterImage
                                ? `${API}${inputs.ReporterImage}`
                                : ""
                            }
                            alt=""
                          />
                        )}
                      </div>
                      <div className="col-md-12">
                        <div class="form-group">
                          <label for="exampleInputFile">Reporter Image</label>
                          <div>
                            <input
                              onChange={(e) =>
                                setprofileimage(e.target.files[0])
                              }
                              name="file"
                              type="file"
                              className="TextArea"
                              id="reporterimage"
                              size={60}
                              maxLength={70}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Reporter's Name
                          </label>
                          <input
                            onChange={handleChange}
                            name="ReporterName"
                            // value={inputs.ReporterName }
                            value={
                              inputs && inputs.ReporterName
                                ? inputs.ReporterName
                                : ""
                            }
                            type="text"
                            class="form-control"
                            placeholder="Enter Your Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Designation
                          </label>
                          <input
                            onChange={handleChange}
                            name="Designation"
                            // value={inputs.Designation}
                            value={
                              inputs && inputs.Designation
                                ? inputs.Designation
                                : ""
                            }
                            type="text"
                            class="form-control"
                            placeholder="Enter Your Designation"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Place</label>
                          <input
                            onChange={handleChange}
                            name="DatePlace"
                            // value={inputs.DatePlace}
                            value={
                              inputs && inputs.DatePlace ? inputs.DatePlace : ""
                            }
                            type="text"
                            class="form-control"
                            placeholder="Enter Your Date/Place"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="taja-space">
                            <label htmlFor="exampleInputPassword1">
                              Heading
                            </label>
                            <div className="col-md-12">
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="Headline"
                                  onChange={handleChange}
                                  value={inputs.Headline}
                                  checked={inputs.Headline}
                                  class="form-check-input mt-2 border-3  border-danger"
                                  id="exampleCheck1"
                                />
                                <label for="exampleCheck1">Taja Smachar</label>
                              </div>
                            </div>
                          </div>

                          <input
                            onChange={handleChange}
                            name="Heading"
                            // value={inputs.Heading}
                            value={
                              inputs && inputs.Heading ? inputs.Heading : ""
                            }
                            class="form-control"
                            placeholder="Enter Your Heading"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Sub-heading
                          </label>
                          <input
                            onChange={handleChange}
                            name="Subheading"
                            // value={inputs.Subheading}
                            value={
                              inputs && inputs.Subheading
                                ? inputs.Subheading
                                : ""
                            }
                            type="text"
                            class="form-control"
                            placeholder="Enter Your subheading"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {image && image instanceof Blob ? (
                          <img
                            className="mt-2"
                            style={{ width: "100px", height: "80px" }}
                            src={URL.createObjectURL(image)}
                            alt=""
                          />
                        ) : (
                          <img
                            className="mt-2"
                            style={{ width: "100px", height: "80px" }}
                            src={inputs.Image ? `${API}${inputs.Image}` : ""}
                            alt=""
                          />
                        )}
                      </div>

                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Image</label>
                          <input
                            type="file"
                            className="form-control"
                            style={{ fontSize: "13px" }}
                            id="inputField"
                            placeholder="Weight"
                            accept="image/*"
                            onChange={(e) => setimage2(e.target.files[0])}
                            name="file"
                          />
                        </div>
                        {image2 &&
                          image2 instanceof Blob && ( // Check if image1 is a Blob or File object
                            <img
                              style={{ width: "82px", marginTop: "10px" }}
                              src={URL.createObjectURL(image2)}
                              alt=""
                            />
                          )}
                      </div> */}

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Image</label>
                          <div className="input-group">
                            <input
                              onChange={(e) => setimage(e.target.files[0])}
                              name="file"
                              style={{ fontSize: "13px" }}
                              type="file"
                              className="form-control"
                              id="imagefirst"
                              size={60}
                              maxLength={60}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Video Upload </label>
                          <input
                            type="file"
                            className="form-control"
                            style={{ fontSize: "13px" }}
                            id="inputField"
                            placeholder="Weight"
                            accept="video/*"
                            onChange={(e) => setVideo(e.target.files)}
                            name="Video"
                          />
                        </div>
                        
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Audio</label>
                          <input
                            type="file"
                            className="form-control"
                            style={{ fontSize: "13px" }}
                            id="inputField"
                            placeholder="Weight"
                            accept="audio/*"
                            onChange={(e) => setAudio(e.target.files)}
                            name="Audio"
                          />
                        </div>
                        {/* <div className="form-group">
                                <label htmlFor="exampleInputFile">Audio</label>

                                <div className="input-group">
                                  <input
                                    onChange={(e) => setAudio(e.target.files)}
                                    name="Audio"
                                    type="file"
                                    className="TextField"
                                    id="imagefirst"
                                    size={60}
                                    maxLength={60}
                                    required
                                  />
                                </div>
                              </div> */}
                      </div>

                      
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Video</label>
                          <div className="input-group">
                            <input
                              onChange={(e) => setVideo(e.target.files)}
                              name="Video"
                              type="file"
                              className="TextField"
                              id="imagefirst"
                              size={60}
                              maxLength={60}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Audio</label>
                          <div className="input-group">
                            <input
                              onChange={(e) => setAudio(e.target.files)}
                              name="Audio"
                              type="file"
                              className="TextField"
                              id="imagefirst"
                              size={60}
                              maxLength={60}
                              required
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Capton</label>
                          <input
                            onChange={handleChange}
                            name="Capton"
                            value={inputs && inputs.Capton ? inputs.Capton : ""}
                            class="form-control"
                            placeholder="Enter Your Heading"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Editor</label>
                      {/* <JoditEditor
                        ref={editor}
                        value={content}
                        // value={inputs.Matter}
                        // config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                        required
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data); // Update the content state with CKEditor's data
                        }}
                        config={{
                          ckfinder: {
                            uploadUrl: `${process.env.REACT_APP_API_URL}/upload`, // Your server URL for image upload
                          },
                          image: {
                            toolbar: [
                              "imageTextAlternative",
                              "|",
                              "imageStyle:full",
                              "imageStyle:side",
                            ],
                          },
                        }}
                        style={{ height: "900px" }} // Set the height directly here
                        required
                      />
                    </div>
                  </div>

                  <div className="card-footer">
                    <button
                      type="submit"
                      onClick={FormSubmit}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditBlogs;
