import React, { useContext, useEffect, useState } from "react";

import ApiCalls from "../../ApiCalls/ApiCalls";
import { ApiContext } from "../../Context/ApiContext";
import { GetTagline, UpdateTagline } from "../../_service_api/ServiceApi";

const EditTagline = () => {

  const [content, setContent] = useState("");
  const [inputs, setInputs] = useState({});

  const getdata = async () => {
     const data = await GetTagline()
        setInputs(data[0]);
      
  };

  useEffect(() => {
    getdata();
  }, []);

  async function FormSubmit(event) {
    event.preventDefault();
    setInputs({ ...inputs, ["CompleteAddress"]: content });

    const response = await UpdateTagline(inputs)
    
    if (response) {
      alert("data add successfully");
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
    console.log(inputs);
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content mt-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">EDIT Tagline</h3>
                  </div>
                  <form
                    onSubmit={() => FormSubmit}
                    action="/post"
                    method="POST"
                    encType="multipart/form-data"
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Tagline</label>
                            <input
                              onChange={handleChange}
                              name="Heading"
                              value={inputs.Heading}
                              type="text"
                              class="form-control"
                              placeholder="Enter Your Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer">
                      <button
                        type="submit"
                        onClick={FormSubmit}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditTagline;
