import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiContext } from "../../Context/ApiContext";
import { LoginUser } from "../../_service_api/ServiceApi";
const Login = () => {
  const { API, setUserInfo } = useContext(ApiContext);
  const [user, setUser] = useState({});
  const { login } = useContext(ApiContext);
  const navigation = useNavigate();
  const ChangeHandle = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });

    // console.log(login)
  };

  const SubmitHandler = async (event) => {
    user.logindate = new Date().toISOString();
    event.preventDefault();

    const response = await LoginUser(user);


    if (response.code === 404) {
      alert("User not found");
    }

    if (response.code === 401) {
      alert("incorrect password");
    }

    if (response.code === 200) {
      setUserInfo(response.data);
      login(response.data);
      navigation("/");
    }

    if (response.code === 403) {
      alert("Your are Blocked by admin");
    }
  };
  return (
    <section class="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <img
                src="./thirdeyeworldnews logo.svg"
                className="img-fluid w-75"
                alt="User Image"
              />
            </div>
            {/* <h4 className="login-box-msg text-center">Please Login Start</h4> */}
            <form className="mb-4 mt-4" onSubmit={(e) => SubmitHandler(e)}>
              <div>
                <label>Enter User Name</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="User_name"
                    className="form-control"
                    onChange={(e) => ChangeHandle(e)}
                    value={user.User_name || ""}
                    placeholder="Username"
                    required
                  />
                </div>
              </div>
              <div>
                <label>Enter Password</label>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => ChangeHandle(e)}
                    value={user.password || ""}
                    required
                    className="form-control"
                  />
                </div>
              </div>
              <div className="mb-3">
                {/* <Link to={'/forget-password'} className="text-white fw-bold ">Forget Password</Link> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
