import SideNavBar from "../../component/sidenav/SideNavBar";
import React, { useContext, useEffect, useState } from "react";
import { useRef, useMemo } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react"; // Corrected import for CKEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; // CKEditor Classic Build
import Select from "react-select";
import { ApiContext } from "../../Context/ApiContext";
import { Addblog, getallcategories } from "../../_service_api/ServiceApi";
import JoditEditor from "jodit-react";

const AddBlogs = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { userinfo } = useContext(ApiContext);
  const [image2, setimage2] = useState({});
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [inputs, setInputs] = useState({});

  const getdata = async () => {
    const data = await getallcategories();
    setSelectedValue((prevSelectedValue) => [
      ...prevSelectedValue, // Previous state values
      ...data.map((item) => ({
        value: item._id,
        label: item.category,
      })), // New state values
    ]);
  };

  useEffect(() => {
    getdata();
  }, []);

  useEffect(() => {
    document.querySelectorAll("input").forEach((input) => {
      input.value = inputs[input.name] || "";
    });
  }, [inputs]);

  const resetInputs = () => {
    setInputs({});
    setContent("");
  };

  async function FormSubmit(event) {
    event.preventDefault();

    // Display a confirmation alert before submitting the form
    const isConfirmed = window.confirm(
      "Are you sure you want to submit this form?"
    );

    if (!isConfirmed) {
      // If the user clicks "Cancel", stop the form submission
      return;
    }

    const formData = await new FormData();

    if (userinfo.name && userinfo.name.length > 0) {
      formData.append("ReporterName", userinfo.name);
    }
    if (userinfo.profile && userinfo.profile.length > 0) {
      formData.append("ReporterImage", userinfo.profile);
    }

    if (userinfo.Destination && userinfo.Destination.length > 0) {
      formData.append("Designation", userinfo.Destination);
    }

    if (userinfo.Place && userinfo.Place.length > 0) {
      formData.append("DatePlace", userinfo.Place);
    }

    const currentDate = new Date().toISOString();
    formData.append("CreationDate", currentDate);

    if (inputs.heading && inputs.heading.length > 0) {
      formData.append("Heading", inputs.heading);
    }

    if (inputs.Capton && inputs.Capton.length > 0) {
      formData.append("Capton", inputs.Capton);
    }

    if (inputs.subheading) {
      formData.append("Subheading", inputs.subheading);
    }

    if (inputs.Headline) {
      formData.append("Headline", inputs.Headline);
    }

    if (image2 instanceof File) {
      formData.append("image", image2);
    }

    if (video.length > 0) {
      formData.append("Video", video[0]);
    }
    if (audio.length > 0) {
      formData.append("Audio", audio[0]);
    }
    if (content) {
      // formData.append('Subheading', inputs.subheading);
      formData.append("Matter", content);
    }
    console.log("FormData:", formData);

    if (Array.isArray(selectedOption)) {
      const positionValues = selectedOption.map((option) => option.value);
      console.warn(positionValues);
      formData.append("Category_id", JSON.stringify(positionValues));
    }
    // formData.append("Category_id", selectedOption.value);

    const data = await Addblog(formData);
    if (data) {
      resetInputs();
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    setInputs({ ...inputs, [name]: value });

    if (name === "Headline") {
      setInputs({ ...inputs, [name]: checked }); // Update the checkbox state only if its name is "isHeader"
    }
  };

  const nhandleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <>
      <main>
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content mt-4">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">Add Khabare</h3>
                      </div>
                      <form
                        onSubmit={() => FormSubmit}
                        action="/post"
                        method="POST"
                        encType="multipart/form-data"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div class="form-group">
                                <label for="exampleSelectRounded0">
                                  Select Location for Display Khabar
                                </label>
                                <Select
                                  id="selectOption"
                                  name="Position"
                                  // value={selectedcategories}
                                  onChange={nhandleChange}
                                  options={selectedValue}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="taja-space">
                                  <label htmlFor="exampleInputPassword1">
                                    Heading
                                  </label>
                                  <div className="col-md-12">
                                    <div class="form-check">
                                      <input
                                        type="checkbox"
                                        name="Headline"
                                        onChange={handleChange}
                                        value={inputs.Headline}
                                        checked={inputs.Headline}
                                        class="form-check-input mt-2 border-3  border-danger"
                                        id="exampleCheck1"
                                      />
                                      <label for="exampleCheck1">
                                        Taja Smachar
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <input
                                  onChange={handleChange}
                                  name="heading"
                                  class="form-control"
                                  placeholder="Enter Your Heading"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">
                                  Sub-heading
                                </label>
                                <input
                                  onChange={handleChange}
                                  name="subheading"
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Your subheading"
                                  required
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Image</label>
                                <div className="input-group">
                                  <input
                                    onChange={(e) =>
                                      setimage2(e.target.files[0])
                                    }
                                    name="file"
                                    type="file"
                                    className="TextField"
                                    id="imagefirst"
                                    size={60}
                                    maxLength={60}
                                    required
                                  />
                                </div>
                              </div>

                              {image2 &&
                                image2 instanceof Blob && ( // Check if image1 is a Blob or File object
                                  <img
                                    style={{ width: "82px", marginTop: "10px" }}
                                    src={URL.createObjectURL(image2)}
                                    alt=""
                                  />
                                )}
                            </div> */}
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Image</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  style={{ fontSize: "13px" }}
                                  id="inputField"
                                  placeholder="Weight"
                                  accept="image/*"
                                  onChange={(e) => setimage2(e.target.files[0])}
                                  name="file"
                                />
                              </div>
                              {image2 &&
                                image2 instanceof Blob && ( // Check if image1 is a Blob or File object
                                  <img
                                    style={{ width: "82px", marginTop: "10px" }}
                                    src={URL.createObjectURL(image2)}
                                    alt=""
                                  />
                                )}
                              
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Video Upload </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  style={{ fontSize: "13px" }}
                                  id="inputField"
                                  placeholder="Weight"
                                  accept="video/*"
                                  onChange={(e) => setVideo(e.target.files)}
                                  name="Video"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label htmlFor="exampleInputFile">Video</label>
                               
                                <div className="input-group">
                                  <input
                                    onChange={(e) => setVideo(e.target.files)}
                                    name="Video"
                                    type="file"
                                    className="TextField"
                                    id="imagefirst"
                                    size={60}
                                    maxLength={60}
                                    required
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Audio</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  style={{ fontSize: "13px" }}
                                  id="inputField"
                                  placeholder="Weight"
                                  accept="audio/*"
                                  onChange={(e) => setAudio(e.target.files)}
                                  name="Audio"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label htmlFor="exampleInputFile">Audio</label>

                                <div className="input-group">
                                  <input
                                    onChange={(e) => setAudio(e.target.files)}
                                    name="Audio"
                                    type="file"
                                    className="TextField"
                                    id="imagefirst"
                                    size={60}
                                    maxLength={60}
                                    required
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">
                                  Capton
                                </label>
                                <input
                                  onChange={handleChange}
                                  name="Capton"
                                  class="form-control"
                                  placeholder="Enter Your Heading"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label>Editor</label>
                            {/* <JoditEditor
                              ref={editor}
                              value={content}
                              // value={inputs.Matter}
                              config={{ height: "500px" }}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                              onChange={(newContent) => {}}
                              required
                            /> */}
                            <CKEditor
                              editor={ClassicEditor}
                              data={content}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(data); // Update the content state with CKEditor's data
                              }}
                              config={{
                                ckfinder: {
                                  uploadUrl: `${process.env.REACT_APP_API_URL}/upload`, // Your server URL for image upload
                                },
                                image: {
                                  toolbar: [
                                    "imageTextAlternative",
                                    "|",
                                    "imageStyle:full",
                                    "imageStyle:side",
                                  ],
                                },
                              }}
                              style={{ height: "900px" }} // Set the height directly here
                              required
                            />
                          </div>
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            onClick={FormSubmit}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddBlogs;
