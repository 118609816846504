import axios from "axios";

const API = process.env.REACT_APP_API_URL;

export async function getcolor() {
  try {
    const response = await axios.get(`${API}/api/colors`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
}

export async function editcolor(data) {
  try {
    const response = await axios.put(
      `${API}/api/colors/65df89e7aec0f43ceb6e6681`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
}

export async function editrules(data) {
  try {
    const response = await axios.put(
      `${API}/api/rules/65f9274e20f9d2761a82aae7`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
}
export async function fetchaddress() {
  try {
    const response = await axios.get(`${API}/api/user/address`);
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function editaddress(content) {
  try {
    const response = await axios.put(
      `${API}/api/address/65f921ca41c0560e07a10771`,
      { CompleteAddress: content },
      {
        
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function LoginUser(logindetails) {
  try {
    const response = await axios.post(`${API}/api/admin/login`, logindetails);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function LoginUserLogs() {
  try {
    const response = await axios.get(`${API}/api/admin/login`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getUser() {
  try {
    const response = await axios.get(`${API}/api/admin/user`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function createUser(formData) {
  try {
    const response = await axios.post(`${API}/api/admin/signup`,formData);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function createTeamMeamber(formData) {
  try {
    const response = await axios.post(`${API}/api/admin/team`,formData);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function geteammemberbyId(id) {
  try {
    const response = await axios.get(`${API}/api/admin/teambyid/${id}`);
    // console.warn("team data by id",response)
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function editTeamMeber(id, data) {
  try {
    const response = await axios.put(`${API}/api/admin/team/${id}`, data);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getUserbyId(id) {
  try {
    const response = await axios.get(`${API}/api/admin/user?_id=${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function editUser(id, data) {
  try {
    const response = await axios.put(`${API}/api/admin/user/${id}`, data);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function deleteUser(id) {
  try {
    const response = await axios.delete(`${API}/api/admin/user/${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
export async function getyoutubevideo() {
  try {
    const response = await axios.get(`${API}/api/admin/youtube`);
    console.log(response);
    return await response.data.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Addyoutubevideo() {
  try {
    const response = await axios.get(`${API}/api/admin/addyoutubevideo`);
    return await response.data.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getcategories() {
  try {
    const response = await axios.get(
      `${API}/api/admin/categories?location=title`
    );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
export async function getallcategories() {
  try {
    const response = await axios.get(`${API}/api/admin/categories`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
export async function getcategoriesbyid(id) {
  try {
    const response = await axios.get(`${API}/api/admin/categories/${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getcategoriesbylocation(location) {
  try {
    const response = await axios.get(
      `${API}/api/admin/categories?location=${location}`
    );

    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getblogbycategorie(currentPage, categories) {
  try {
    const response = await axios.get(
      `${API}/api/admin/blogsbycatid?page=${currentPage}&limit=10&Category=${categories}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
export async function getblogbyid(id) {
  try {
    const response = await axios.get(`${API}/api/admin/blogs?_id=${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Addblog(formData) {
  try {
    const response = await axios.post(`${API}/api/admin/blogs`, formData);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Editblogs(id,data) {
  try {
    const response = await axios.put(`${API}/api/admin/blogs/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function updateblogstatus(id, data) {
  try {
    const response = await axios.put(`${API}/api/admin/blogs/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function DeleteOneBlog(id) {
  try {
    const response = await axios.delete(`${API}/api/admin/blogs/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function DeletMultipleeBlogs(selectedItems) {
  try {
    const response = await axios.delete(`${API}/api/admin/blogs`, {
      data: { ids: selectedItems }, // Sending data in the body
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function UpdateMultipleeBlogsStatus(selectedItems, status) {
  try {
    const response = await axios.put(`${API}/api/admin/blogs`, {
      data: { ids: selectedItems, status: status }, // Sending data in the body
      
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Editcategoriesbyid(id, formData) {
  try {
    const response = await axios.put(`${API}/api/admin/categories/${id}`, formData);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function addcategories(formData) {
  try {
    const response = await axios.post(`${API}/api/admin/categories`, {
      data: formData, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function EditRulesById(formData) {
  try {
    const response = await axios.put(
      `${API}/api/admin/rules/65f9274e20f9d2761a82aae7`,
      {
        data: formData, // Sending data in the body
        
      }
    );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function AddOptionPoll(formData) {
  try {
    const response = await axios.post(`${API}/api/admin/poll`, {
      data: formData, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function EditOptionPoll(formData) {
  try {
    const response = await axios.put(`${API}/api/admin/poll`, {
      data: formData, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function GetOptionPoll() {
  try {
    const response = await axios.get(`${API}/api/admin/poll`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function DeleteOptionPoll(id) {
  try {
    const response = await axios.delete(`${API}/api/admin/poll/${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function AddAdvertise(formData) {
  try {
    const response = await axios.post(`${API}/api/admin/advert`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function EditAdvertise(id, Data) {
  try {
    const response = await axios.put(`${API}/api/admin/advert/${id}`, {
      data: Data, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getAdvertisebyId(id) {
  try {
    const response = await axios.get(`${API}/api/admin/advert?_id=${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
export async function getAllAdvertise(id) {
  try {
    const response = await axios.get(`${API}/api/admin/advert`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
export async function updateAdvertiseStatus(id, status) {
  try {
    const response = await axios.put(`${API}/api/admin/advert/${id}`, {
      data: status, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function DeleteAdvertise(id) {
  try {
    const response = await axios.delete(`${API}/api/admin/advert/${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Addfounder(Data) {
  try {
    const response = await axios.post(`${API}/api/admin/founder`, {
      data: Data, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Getfounder() {
  try {
    const response = await axios.get(`${API}/api/admin/founder`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Editfounder(Data) {
  try {
    const response = await axios.put(
      `${API}/api/admin/founder/65f927e820f9d2761a82aaed`, Data, 
    );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function getsubscriber() {
  try {
    const response = await axios.get(`${API}/api/admin/Subscribers`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function Deletesubscriber(id) {
  try {
    const response = await axios.delete(`${API}/api/admin/Subscribers/${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function UpdatesubscriberStatus(id, Data) {
  try {
    const response = await axios.put(`${API}/api/admin/Subscribers/${id}`, {
      data: Data, // Sending data in the body
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function GetTagline() {
  try {
    const response = await axios.get(
      `${API}/api/admin/tagline?_id=6602a87711e47f88c9059347`
    );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function UpdateTagline(Data) {
  try {
    const response = await axios.put(
      `${API}/api/admin/tagline?_id=6602a87711e47f88c9059347`,
      {
        data: Data, // Sending data in the body
        
      }
    );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function GetAllTajasamachar() {
  try {
    const response = await axios.get(`${API}/api/admin/tajasamachar`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function GetTajasamacharById(id) {
  try {
    const response = await axios.get(`${API}/api/admin/tajasamachar?_id=${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function UpdateTajasamachar(id, Data) {
  try {
    const response = await axios.put(`${API}/api/admin/tajasamachar/${id}`, {
      data: Data, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function UpdateTajasamacharStatus(id, Data) {
  try {
    const response = await axios.put(`${API}/api/admin/tajasamachar/${id}`, {
      data: Data, // Sending data in the body
      
    });
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function DeleteTajasamachar(id) {
  try {
    const response = await axios.delete(`${API}/api/admin/tajasamachar/${id}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function AddTajasamachar(Data) {
  try {
    const response = await axios.post(`${API}/api/admin/tajasamachar`, Data );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function GetAddress(id) {
  try {
    const response = await axios.get(`${API}/api/admin/address`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function UpdateAddress(Data) {
  try {
    const response = await axios.put(
      `${API}/api/admin/address/65f921ca41c0560e07a10771`,
      {
        data: Data, // Sending data in the body
        
      }
    );
    return await response.data;
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}
